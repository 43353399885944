import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "katex/dist/katex.min.css"
import SEO from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  function getKanji(level) {
    var kanji=""
    level = level.toLowerCase()
    switch (level) {
      case "shu":
        kanji="easy";
        break;
      case "ha":
        kanji="medium";
        break;
      case "ri":
        kanji="hard";
        break;
      default:
        kanji="";
        break;
    }
    return kanji;
  }
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
    <SEO title={frontmatter.title} description={frontmatter.description || frontmatter.title} />
    <div className="blog-post-container">
      <div className="blog-post">
        <h1>{frontmatter.title}</h1>
        <div>{(frontmatter.date!=null)?frontmatter.date+",":""}<strong>tags</strong> : {frontmatter.tags.join(",")}, <strong>level</strong>: {frontmatter.level+", "+getKanji(frontmatter.level)}</div>
        <div style={{"color":"red", 'fontWeight': "700"}}>{frontmatter.draft===true?"<This is a draft, it is work in progress>":""}</div>
        <br/>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }  }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        tags
        draft
        level
      }
    }
  }
`